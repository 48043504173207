.user-role-management-table{
  table{
    th,td{
      &:first-child{
        width: 15%;
      }
      &:nth-child(2){
        width: 20%;
      }
      &:nth-child(3){
        width: 30%;
      }

      &:nth-child(4){
        width: 12%;
      }
      &:nth-child(5){
        width: 12%;
      }
      &:nth-child(6){
        width: 12%;
      }
      a{
        color: $heading-color;
        cursor: pointer;
      }
      
    }
    tr{
      &:hover{
        a{
          text-decoration: underline;
          color: $accent-color;
        }
      }
    }
  }
}

.role-managment-detail-popup{
  .modal-main{
    width: 980px;
    .modal-header{
      display: flex;
      align-items: center;
      padding-bottom: 24px;
      h2{
        padding-bottom: 0px;
      }
      .btn-line{
        min-width: auto;
        padding: 0px 15px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        .ic-edit{
          font-size: 20px ;
        }
      }
    }
    ul{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: -10px;
      margin-left: -10px;
      li{
        width: calc(33.33% - 20px);
        padding: 0px  10px;
        line-height: 38px;
        font-size: 12px;
        .ic-check{
          color: $accent-color;
          margin-right: 10px;
        }
        .ic-clearclose{         
          margin-right: 10px;
        }
      }
    }
  }
}

.align-center{
  align-items: center;
}
.right-detail-list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  width: calc(100% + 20px);
  li{
    width: calc(33.33% - 20px) !important;
    padding: 0px  10px;
    line-height: 38px;
    font-size: 12px;
    .custom-control-label{
      font-size: 12px;
    }
  }

}

.role-informatiom{
  ul{
  flex-wrap: wrap;
  li{
    padding-bottom: 15px;
    &:last-child{
      width: 100%;
    }
  }
}
}
.role-detail-preview{
  .right-detail-list{
    li{
      padding-bottom: 15px;
    }
  }
}
.region-tooltip{
  display: flex !important;
  align-items: center;
}
.role-detail-tabs{
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.br-radius{
  border-radius: 8px 8px 0 0 ;
}

.custom-notification-file-upload {
  .modal-main {
    width: 500px;
  }
}