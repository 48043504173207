.payout-detail-table {
  padding-top: 30px;

  .custom-control {
      display: inline-block;
  }

  .ic-keyboard_arrow_down {
      font-size: 26px;
  }

  .data-table {
      table {
          tr {

              th,
              td {
                  &:nth-child(1) {
                      width: 4%;
                      padding-left: 20px;
                      text-wrap: wrap;
                  }
                  &:nth-child(2) {
                    width: 6%;
                    padding-left: 20px;
                    text-wrap: wrap;
                  }
                  &:nth-child(3) {
                    width: 11%;
                    padding-left: 20px;
                    text-wrap: wrap;
                  }
                  &:nth-child(4) {
                    width: 12%;
                    padding-left: 20px;
                    text-wrap: wrap;
                  }
                  &:nth-child(5) {
                    width: 12%;
                    padding-left: 20px;
                    text-wrap: wrap;
                  }
                  &:nth-child(6) {
                    width: 10%;
                    padding-left: 20px;
                    text-wrap: wrap;
                  }
                  &:nth-child(7){
                    width: 10%;
                    padding-left: 20px;
                    text-wrap: wrap;
                  }
                  &:nth-child(8){
                    width: 10%;
                    padding-left: 20px;
                    text-wrap: wrap;
                  }
                  &:nth-child(9){
                    width: 10%;
                    padding-left: 20px;
                    text-wrap: wrap;
                  }
                
                
              }

              td {
                  vertical-align: top;
              }

              th {
                  .info-icn {
                      display: inline-block;
                      position: relative;

                      .info-text-detail {
                          left: -180px;
                      }
                  }
              }

              .loan-id-arrow {
                  &::before {
                      content: "\e917";
                      font-family: dc !important;
                      font-size: 24px;
                      position: relative;
                      top: 6px;
                      margin-right: 8px;
                  }
              }

              &.active {
                  background: #F6F6F6 !important;
                  border-bottom: 0px;

                  .loan-id-arrow {
                      &::before {
                          content: "\e91a";
                          font-family: dc !important;
                          font-size: 24px;
                          position: relative;
                          top: 6px;
                          margin-right: 8px;
                      }
                  }
              }
          }

          .subtable {
              thead {
                  tr {
                      background: #E3E7F1;

                      th {
                          &:first-child {
                              padding-left: 50px !important;
                          }
                      }
                  }
              }

              td {
                  background: #F6F6F6;
                  padding-left: 30px !important;
                  padding-right: 30px !important;
                  padding-top: 0px;
                  vertical-align: middle;

                  table {
                      tr {
                          th {
                              width: 10%;

                              &:nth-child(4) {
                                  width: 14%;
                              }

                              &:nth-child(8) {
                                  width: 11%;
                              }

                          }
                      }
                  }
              }

              table {
                  width: 100%;
                  overflow-y: auto;
                  display: block;

                  tbody {
                      td {
                          background: $white !important;
                          padding-left: 20px !important;
                          padding-right: 20px !important;
                          padding-top: 13px;

                          &:first-child {
                              padding-left: 50px !important;
                          }
                      }
                  }
              }

              tr {
                  &.rejected {
                      td {
                          opacity: 0.4;

                          &:last-child {
                              color: #FF3636;
                              opacity: 1;
                          }
                      }
                  }
              }
          }

          .ic-file_icn {
              position: absolute;
              margin-left: -22px;
              color: #000;
              font-size: 17px;
          }

          .ic-check {
              color: #59B74A;
          }

          .ic-clearclose {
              color: #FF3636;
          }
      }

      .link-txt {
          text-decoration: underline;
          cursor: pointer;
      }
  }

  .action-btn {
      display: flex;
      align-items: center;

      li {
          height: 32px;
          width: 32px;
          min-width: 32px;
          border-radius: 4px;
          border: 1px solid #e0e0e0;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          cursor: pointer;
          color: $heading-color;

          .ic-email1 {
              font-size: 22px;
          }

          .ic-otp {
              font-size: 23px;
          }
      }
  }
}


.Progression-top-filter{
    background: $white;
    padding: 20px;
    border-radius: 8px;
    .search-bx{
      margin-right: 8px;
      width: 238px;
    }
    .single-select{
      margin-bottom: 0px;
    }
}
.Progression-user-detail-outer{
  .sub-heading{
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .tab-line{
    background: $white;
    padding: 20px 30px 10px; 
    border-radius: 8px;
  }

.payout-summery-right-panel {
        padding: 30px 30px 0px 30px;
    ul {
        display: flex;
        align-items: center;

        li {
            color: rgba($heading-color, 0.7);
            font-size: 22px;
            padding-right: 50px;
            text-align: center;

            span {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                display: block;
            }

            label {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                display: block;
                color: rgba($heading-color, 0.6);
            }

            .total-payout-txt {
                color: #34C48B;
            }

            .total-incentives-txt {
                color: #487CFD;
            }

            .total-allowance {
                color: #FFA340;
            }

            .paid-rp-txt {
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                font-weight: 500;
            }

            

        }
    }
}
}

.sales-target-upload {
    width: 500px;
}